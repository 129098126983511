export const environment = {
  production: false,
  apiUrl: 'https://gereturns-api-prd.cc.pitneycloud.com',
  loginPageUrl: 'https://login2.pitneybowes.com/login?productId=geapp',
  logoutPageUrl: 'https://login2.pitneybowes.com/logout',
  okta: {
    issuer: 'https://pitneybowes.okta.com/oauth2/ausb4rdf6sP4Vyptu1t7',
    clientId: '0oapmxozqzwg73NwW1t7',
    redirectUri: window.location.origin + '/login/callback',
    scopes: ['geadmin', 'openid', 'profile', 'email'],
  },
};
