/* istanbul ignore file */

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { getLoginPageUrl } from '@app/_auth';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(DOCUMENT) private document: Document, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  async handleAccess(request: HttpRequest<any>, next: HttpHandler) {
    // Only add an access token to whitelisted origins
    const allowedOrigins = [environment.apiUrl];
    if (allowedOrigins.some((url) => request.urlWithParams.includes(url)) && this.oktaAuth.getAccessToken()) {
      const accessToken = this.oktaAuth.getAccessToken();
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + accessToken,
        },
      });
    }

    return next
      .handle(request)
      .pipe(
        tap(
          () => {},
          (err) => {
            if (err.status !== 401) {
              return;
            }

            this.document.location.href = getLoginPageUrl(this.oktaAuth);
          }
        )
      )
      .toPromise();
  }
}
