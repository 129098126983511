/* istanbul ignore file */

import { Component, OnInit, Optional, Injector, Inject } from '@angular/core';
import { OktaAuth, Tokens, AccessToken, IDToken, getOAuthUrls } from '@okta/okta-auth-js';
import { OKTA_CONFIG, OktaConfig, OKTA_AUTH } from '@okta/okta-angular';

@Component({
  template: `<div>{{ error }}</div>`,
})
export class OktaCallbackComponent implements OnInit {
  error: string;

  constructor(
    @Inject(OKTA_CONFIG) private config: OktaConfig,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    @Optional() private injector?: Injector
  ) {}

  async ngOnInit(): Promise<void> {
    const nativeLoc = this.oktaAuth.token.parseFromUrl._getLocation();
    const hash = nativeLoc.hash;

    const urls = getOAuthUrls(this.oktaAuth);
    const token: any = this.hashToObject(hash);
    const jwt = this.oktaAuth.token.decode(token.id_token);

    const accessToken: AccessToken = {
      accessToken: token.access_token,
      expiresAt: Number(token.expires_in) + Math.floor(Date.now() / 1000),
      tokenType: token.token_type,
      scopes: token.scope.split(' '),
      authorizeUrl: urls.authorizeUrl,
      userinfoUrl: urls.userinfoUrl,
      claims: jwt.payload,
    };

    const now = Math.floor(Date.now() / 1000);
    const idToken: IDToken = {
      idToken: token.id_token,
      claims: jwt.payload,
      expiresAt: jwt.payload.exp - jwt.payload.iat + now, // adjusting expiresAt to be in local time
      scopes: token.scope.split(' '),
      authorizeUrl: urls.authorizeUrl,
      issuer: urls.issuer,
      clientId: this.oktaAuth.options.clientId,
    };

    const tokens: Tokens = {
      accessToken: accessToken,
      idToken: idToken,
    };

    try {
      // Parse code or tokens from the URL, store tokens in the TokenManager, and redirect back to the originalUri
      await this.oktaAuth.handleLoginRedirect(tokens);

      return;
    } catch (e) {
      // Callback from social IDP. Show custom login page to continue.
      if (this.oktaAuth.idx.isInteractionRequiredError(e) && this.injector) {
        const { onAuthResume, onAuthRequired } = this.config;
        const callbackFn = onAuthResume || onAuthRequired;
        if (callbackFn) {
          callbackFn(this.oktaAuth, this.injector);

          return;
        }
      }
      this.error = e.toString();
    }
  }

  private hashToObject(hash: string): any {
    // Predefine regexs for parsing hash
    const plus2space = /\+/g;
    const paramSplit = /([^&=]+)=?([^&]*)/g;

    // Remove the leading hash
    const fragment = hash.substring(1);

    const obj = {};

    // Loop until we have no more params
    let param;
    while (true) {
      // eslint-disable-line no-constant-condition
      param = paramSplit.exec(fragment);
      if (!param) {
        break;
      }

      const key = param[1];
      const value = param[2];

      // id_token should remain base64url encoded
      if (key === 'id_token' || key === 'access_token' || key === 'code') {
        obj[key] = value;
      } else {
        obj[key] = decodeURIComponent(value.replace(plus2space, ' '));
      }
    }

    return obj;
  }
}
