/* istanbul ignore file */

import { Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  OktaAuth,
  OktaAuthOptions,
  buildAuthorizeParams,
  getOAuthUrls,
  getDefaultTokenParams,
} from '@okta/okta-auth-js';

import { environment } from '@env/environment';

const config: OktaAuthOptions = {
  issuer: environment.okta.issuer,
  clientId: environment.okta.clientId,
  redirectUri: environment.okta.redirectUri,
  scopes: environment.okta.scopes,
};

export const oktaAuth = new OktaAuth(config);

export const getAuthorizeUrl = (oktaAuth: OktaAuth): string => {
  const tokenParams = getDefaultTokenParams(oktaAuth);

  const urls = getOAuthUrls(oktaAuth);
  return urls.authorizeUrl + buildAuthorizeParams(tokenParams);
};

export const getLoginPageUrl = (oktaAuth: OktaAuth): string => {
  const resourceUrl = getAuthorizeUrl(oktaAuth);

  return `${environment.loginPageUrl}&TargetResource=${encodeURIComponent(resourceUrl)}`;
};

export const onAuthRequired = (oktaAuth: OktaAuth, injector: Injector) => {
  const document = injector.get(DOCUMENT);
  document.location.href = getLoginPageUrl(oktaAuth);
};
