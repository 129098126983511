import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { OktaCallbackComponent } from '@app/_auth';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'technicians',
    loadChildren: () => import('./technicians/technicians.module').then((m) => m.TechniciansModule),
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'admins',
    loadChildren: () => import('./admins/admins.module').then((m) => m.AdminsModule),
    canActivate: [OktaAuthGuard],
  },
  {
    path: 'schedule/:techId',
    loadChildren: () => import('./schedule/schedule.module').then((m) => m.ScheduleModule),
  },
  {
    path: 'accessibility',
    loadChildren: () => import('./accessibility/accessibility.module').then((m) => m.AccessibilityModule),
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
