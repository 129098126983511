<div class="site-wrapper">
  <div>
    <router-outlet name="header"></router-outlet>

    <router-outlet class="site-content"></router-outlet>
  </div>

  <router-outlet name="footer"></router-outlet>
</div>

<ngx-loading-bar color="#009bdf"></ngx-loading-bar>
